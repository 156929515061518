import { useEffect, useRef, useState } from 'react';
import backgroundDesktop from '../../resouces/calendar-desktop.jpg';
// import backgroundMobile from '../../resouces/calendar-mobile.jpg';
const Countdown = () => {
	const Ref = useRef(null);

	const [remainDays, setRemainDays] = useState();
	const [remainHours, setRemainHours] = useState();
	const [remainMinutes, setRemainMinutes] = useState();
	const [remainSeconds, setRemainSeconds] = useState();
	const DEFAULT_WEDDING_TIME = 1688630400000 // 15h 6th July 2023

	const calculateTimeLeft = (currentDate, endDate) => {
    if(currentDate && endDate){
        const timeleft = endDate - currentDate;

        const days = Math.floor( timeleft / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeleft % (1000 * 60)) / 1000);

        let isRunoutOfTime = false;

        if(days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
            isRunoutOfTime = true;
        }

        return {
            days: days,
            hours: hours < 10 ? `0${hours}` : hours,
            minutes: minutes < 10 ? `0${minutes}` : minutes,
            seconds: seconds < 10 ? `0${seconds}` : seconds,
            isRunoutOfTime : isRunoutOfTime
        };
    }

    return null;
    
	}

	const handleCountdown = () => {
		// setTimer('00:00:10');
		if(Ref.interval){
			clearInterval(Ref.interval)
		}
		const setData = setInterval(() => {
			const todayTime = Date.parse(new Date());
			const timeLeft = calculateTimeLeft(todayTime, DEFAULT_WEDDING_TIME);
			if(timeLeft) {
				setRemainDays(daysText => daysText = timeLeft.days);
				setRemainHours(hoursText => hoursText = timeLeft.hours);
				setRemainMinutes(minutesText => minutesText = timeLeft.minutes);
				setRemainSeconds(secondsText => secondsText = timeLeft.seconds);

				if(timeLeft.isRunoutOfTime){
					setRemainDays(0);
					setRemainHours(0);
					setRemainMinutes(0);
					setRemainSeconds(0);
					clearInterval(setData)
					return;
				}
			}
		}, 1000);
		Ref.interval = setData;
	}

	useEffect(() => {
		handleCountdown();
	}, []);

  return(
		<section className="countdown" >
			<div className="countdown--background">
				<picture>
					<source
						srcSet={backgroundDesktop}
						media="(min-width: 768px)"
					/>
					<img src={backgroundDesktop} alt="calendar gif"></img>
				</picture>
			</div>
			{/*<div className="countdown--calendar">
				
				<picture>
					<img src={calendarGif} alt="calendar gif"></img>
				</picture>
			</div>*/}
			<div className="countdown--clock">
				<p className="clock-header">The big day <br/> 6th July 2023</p>
				<div className="clock-countdown">
					<div className="countdowns days">
						<div className="countdowns--number">{remainDays}</div> 
						<div className="countdowns--text">Days</div>
					</div>
					<div className="countdowns hours">
						<div className="countdowns--number">{remainHours}</div>
						<div className="countdowns--text">Hours</div>
					</div>
					<div className="countdowns minutes">
						<div className="countdowns--number">{remainMinutes}</div>
						<div className="countdowns--text">Minutes</div></div>
					<div className="countdowns seconds">
						<div className="countdowns--number">{remainSeconds}</div>
						<div className="countdowns--text">Seconds</div></div>
				</div>
			</div>
		</section>

  )
};

export default Countdown;