// import '../../style/miscalenous/_icons.scss'
import firstItemImage from '../../resouces/album/1.jpg';
import secondItemImage from '../../resouces/album/6.jpg';
import thirdItemImage from '../../resouces/album/18.jpg';
import forthItemImage from '../../resouces/album/45.jpg';
import {ADDRESS_HUSBAND, ADDRESS_WIFE, ADDRES_WEDDING_SITE_HUSBAND, ADDRES_WEDDING_SITE_WIFE, BASE_BREAKPOINT, BUTTON_LOADING_CLASS} from'../../utils/constant/_constant'
import { Bounce, Fade, Flip, Hinge, Roll } from "react-awesome-reveal";
import {useEffect, useState} from 'react';
import {get} from '../../service/http-request';
import Invitation from './_invitation/_index';

const TYPE_OF_EVENT = {
	VU_QUY: "lễ vu quy",
	TIEC_CUOI_NHA_TRAI: "tiệc cưới nhà trai",
	THANH_HON: "lễ thành hôn",
	TIEC_CUOI_NHA_GAI: "tiệc cưới nhà gái"
}

const Timeline = () => {
	const [delayDefault, setDelayDefault] = useState();
	const [loadingClass, setloadingClass] = useState("");
	const [formTitle, setFormTitle] = useState("");
	const [isGroom, setIsGroom] = useState(false);
	const [isShowInvitation, setIsShowInvitation] = useState(false);
	const [clientName, setClientName] = useState('');
	const [isVuQuy, setIsVuQuy] = useState(false);
	function onOpenMapClick(longitude, latitude) {
		window.open(`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`, '_blank');
	}

	const openForm = (eventType = TYPE_OF_EVENT.VU_QUY) => {
		setFormTitle(eventType);
		const timelineFormElement = document.querySelector('.timeline--form');
		const overlay = document.querySelector('.overlay');
		const timeline = document.querySelector('.timeline');
		timeline.scrollIntoView();

		const vehicleRegisterFormGroup = timelineFormElement.querySelector('.vehicle-register-form');

		if(eventType === TYPE_OF_EVENT.TIEC_CUOI_NHA_TRAI || eventType === TYPE_OF_EVENT.TIEC_CUOI_NHA_GAI){
			vehicleRegisterFormGroup.style.display = "flex"
		}else{
			vehicleRegisterFormGroup.style.display = "none"
		}
		overlay.style.display = 'block';
		timelineFormElement.style.display = 'flex';
		document.body.style.overflowY = "hidden";
	}

	const onSubmitForm = (eventType) => {
		const nameInput = document.querySelector('#name-register');
		const isVehicleInput = document.querySelector('#vehicle-register');
		const totalPeopleInput = document.querySelector('#number-people-register');
		const title = eventType;
		const nameValue = nameInput.value;
		const totalValue = totalPeopleInput.value;
		const isBookingVehicle = isVehicleInput.checked;

		const isValidForm = validationForm(nameValue);

		if(isValidForm) {

			const convertTotalValue = totalValue && !isNaN(totalValue) ? parseInt(totalValue) : 1;
			const url = `https://addbooking-${process.env.REACT_APP_firebase_key}-uc.a.run.app?name=${nameValue}&title=${title}&total=${convertTotalValue}&isBookingVehicle=${isBookingVehicle}`;
			setloadingClass(BUTTON_LOADING_CLASS);
			
			get(url).then((response) => {
				setClientName(nameValue);
				nameInput.value = '';
				isVehicleInput.checked = false;
				totalPeopleInput.value = '';
				onOpenInvitation();
			}).catch(error => {
				console.error(error)
				nameInput.value = '';
				isVehicleInput.checked = false;
				totalPeopleInput.value = ''
				setIsShowInvitation(false);
			}).finally(() => {
				setloadingClass("not-loaded");
				if(eventType === TYPE_OF_EVENT.TIEC_CUOI_NHA_TRAI || eventType === TYPE_OF_EVENT.THANH_HON) {
					setIsGroom(true);
				}else if(eventType === TYPE_OF_EVENT.VU_QUY){
					setIsVuQuy(true);
				}else{
					setIsGroom(false);
					setIsVuQuy(false);
				}
				onCloseForm();
			})

		}


		
	}

	const onCloseForm = (isCompleteClose = false) => {
		const timelineFormElement = document.querySelector('.timeline--form');
		timelineFormElement.style.display = 'none';

		if(isCompleteClose) {
			const overlay = document.querySelector('.overlay');
			overlay.style.display = "none";
			document.body.style.overflowY = "auto";

			const inputErrorName = document.querySelector('.input-error.name');
			const nameInput = document.querySelector('#name-register');
			const isVehicleInput = document.querySelector('#vehicle-register');
			const totalPeopleInput = document.querySelector('#number-people-register');
			if(inputErrorName)  inputErrorName.innerHTML = '';
			if(nameInput) nameInput.value = '';
			if(isVehicleInput) isVehicleInput.checked = false;
			if(totalPeopleInput) totalPeopleInput.value = '';
		}
	}

	const validationForm = (nameValue, totalValue) => {
		const inputErrorName = document.querySelector('.input-error.name');

		let isValidate = true;
		if(!nameValue){
			inputErrorName.innerHTML = 'Xin hãy điền tên vào chỗ trống'
			isValidate = false;
		}else{
			inputErrorName.innerHTML = ''
		}

		return isValidate
	}

	const onOpenInvitation = () => {
		const openInvitationComp = document.querySelector('.online-invitation');
		if(openInvitationComp)
			openInvitationComp.style.display = 'flex'
		setIsShowInvitation(true);
	}

	useEffect(() => {
		if(window.innerWidth <= BASE_BREAKPOINT.MAX_MOBILE){
			setDelayDefault(0);
		}else{
			setDelayDefault(1);
		}
	}, [])

  return(
		<section className="timeline" >
			<div className='wide'>
				<div className="timeline--header">
					<p>Sự kiện cưới</p>
				</div>

				<div className="timeline--content">				
					<div className="content--item" id={TYPE_OF_EVENT.VU_QUY}>
						{
							window.innerWidth > BASE_BREAKPOINT.MAX_MOBILE ?
							(<Fade delay={delayDefault * 100} fraction={0.8} duration={1500} triggerOnce={true} >
								<div className='item-image'>
									<img src={thirdItemImage} alt="timeline-item"></img>					
								</div>
							</Fade>):
							(<Roll fraction={0.3} duration={750} triggerOnce={true}  >
								<div className='item-image'>
									<img src={thirdItemImage} alt="timeline-item"></img>					
								</div>
							</Roll>)
						}
						
						
						<div className="item-description">
							<p className="header">Lễ Vu Quy</p>
							<p className="date">11h00 06-07-2023</p>
							<p className="address">Quảng Bị, Chương Mỹ, Hà Nội</p>
							<button className="function-open-map button button--primary" 
								onClick={() => onOpenMapClick(ADDRESS_WIFE.longtitude, ADDRESS_WIFE.latitude)}>
								Mở Bản Đồ
							</button>
							<button className="function-open-map button button--secondary" 
								onClick={() => openForm(TYPE_OF_EVENT.VU_QUY)}>
								Xác nhận tham dự
							</button>
						</div>
					</div>
					<div className="content--item" id={TYPE_OF_EVENT.TIEC_CUOI_NHA_GAI}>
						{
							window.innerWidth > BASE_BREAKPOINT.MAX_MOBILE ?
							(<Fade delay={delayDefault * 300} fraction={0.8} duration={1500} triggerOnce={true}>
								<div className='item-image'>
									<img src={secondItemImage} alt="timeline-item"></img>					
								</div>	
							</Fade>):
							(<Bounce fraction={0.5} duration={850} triggerOnce={true}>
								<div className='item-image'>
									<img src={secondItemImage} alt="timeline-item"></img>					
								</div>	
							</Bounce>)
						}
						
									
						<div className="item-description">
							<p className="header">Tiệc Cưới Nhà Gái</p>
							<p className="date">17h00 05-07-2023</p>
							<p className="address">Trường tiểu học Quảng Bị, Hà Nội</p>
							<button className="function-open-map button button--primary" 
								onClick={() => onOpenMapClick(ADDRES_WEDDING_SITE_WIFE.longtitude, ADDRES_WEDDING_SITE_WIFE.latitude)}>Mở Bản Đồ</button>
							<button className="function-open-map button button--secondary" 
								onClick={() => openForm(TYPE_OF_EVENT.TIEC_CUOI_NHA_GAI)}>
								Xác nhận tham dự
							</button>
						</div>
					</div>
					<div className="content--item" id={TYPE_OF_EVENT.THANH_HON}>
						{
							window.innerWidth > BASE_BREAKPOINT.MAX_MOBILE ?
							(<Fade delay={delayDefault * 600} fraction={0.8} duration={1500} triggerOnce={true}>
								<div className='item-image'>
									
									<img src={firstItemImage} alt="timeline-item"></img>
								</div>
							</Fade>) : 
							(<Roll fraction={0.3} duration={750} triggerOnce={true}>
								<div className='item-image'>
									
									<img src={firstItemImage} alt="timeline-item"></img>
								</div>
							</Roll>)
						}
						
										
						<div className="item-description">
							<p className="header">Lễ Thành Hôn</p>
							<p className="date">15h00 06-07-2023</p>
							<p className="address">Hào Khê, Hải An, Hải Phòng</p>
							<button className="function-open-map button button--primary" 
								onClick={() => onOpenMapClick(ADDRESS_HUSBAND.longtitude, ADDRESS_HUSBAND.latitude)}>Mở Bản Đồ
							</button>
							<button className="function-open-map button button--secondary" 
								onClick={() => openForm(TYPE_OF_EVENT.THANH_HON)}>
								Xác nhận tham dự
							</button>
						</div>
					</div>
					
					
					<div className="content--item" id={TYPE_OF_EVENT.TIEC_CUOI_NHA_TRAI}>
						{
							window.innerWidth > BASE_BREAKPOINT.MAX_MOBILE ?
							(<Fade delay={delayDefault * 900} fraction={0.8} duration={1500} triggerOnce={true}>
								<div className='item-image'>
									<img src={forthItemImage} alt="timeline-item"></img>					
								</div>
							</Fade>):
							(<Bounce fraction={0.5} duration={850} triggerOnce={true}>
								<div className='item-image'>
									<img src={forthItemImage} alt="timeline-item"></img>					
								</div>
							</Bounce>)
						}
						
										
						<div className="item-description">
							<p className="header">Tiệc Cưới Nhà Trai</p>
							<p className="date">17h30 06-07-2023</p>
							<p className="address">Trung tâm tiệc cưới Hải Đăng</p>
							<button className="function-open-map button button--primary" 
								onClick={() => onOpenMapClick(ADDRES_WEDDING_SITE_HUSBAND.longtitude, ADDRES_WEDDING_SITE_HUSBAND.latitude)}>Mở Bản Đồ</button>
							<button className="function-open-map button button--secondary" 
								onClick={() => openForm(TYPE_OF_EVENT.TIEC_CUOI_NHA_TRAI)}>
								Xác nhận tham dự
							</button>
						</div>
					</div>
					
					
					
					

				</div>
				<div className='overlay'></div>
				<div className='timeline--form content-form'>
					<div className='form-title'>Xác nhận tham dự {formTitle}</div>
					<div className='form-subtitle'>Xin chân thành cảm ơn bạn đã lựa chọn tham dự sự kiện cưới của chúng tôi</div>
					<div className='form-container '>
						<div className='form-group'>
							<input id="name-register" className='text' type='text' placeholder='Họ và tên' required/>
							<p className='input-error name'></p>
						</div>
						<div className='form-group vehicle-register-form'>
							<input id="vehicle-register" name="vehicle" className='checkbox' type='checkbox' />
							<label htmlFor="vehicle-register">Đăng ký đi xe</label>
						</div>
						<div className='form-group '>
							<input id="number-people-register" className='text' name="number-people" type='number' placeholder='Số lượng người đi'  required/>
							<p className='input-error number-people'></p>
						</div>

						<div className='form-group form-button'>
							<button className={'content-form-button button button--primary ' + loadingClass} onClick={() => onSubmitForm(formTitle)}>
								<span className='button-text'>Xác nhận</span>
								<span className='button-loading-icon icons icons-loading'></span>
							</button>

							<button className="content-form-button button button--secondary" onClick={() => onCloseForm(true)}>
								<span className='button-text'>Đóng</span>
								<span className='button-loading-icon icons icons-loading'></span>
							</button>
						</div>
					</div>
					
				</div>
				<Invitation isGroom={isGroom} clientName={clientName} isVuQuy={isVuQuy}/> 
				

			</div>
		</section>

  )
};

export default Timeline;