
import {FULL_ALBUM_IMAGE_PATH, ALBUM_IMAGE_EXTENSION, TOTAL_IMAGE_FULL_ALBUM} from'../utils/constant/_constant'
import { randomIntFromInterval } from '../utils/utils-function'
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import {useState} from 'react';
import {Link} from 'react-router-dom';

const FullAlbum = () => {
	const [index, setIndex] = useState(-1);

	const processWholeAlbumPhoto = () => {
		const listPhoto = [];
		for (let index = 0; index < TOTAL_IMAGE_FULL_ALBUM; index++) {
			if(index === 0){
				const photo = {
					src: FULL_ALBUM_IMAGE_PATH + `${index + 1}` + ALBUM_IMAGE_EXTENSION,
					width: 960,
					height: 1400
				}
				listPhoto.push(photo)
			}else{
				const randomIntegerWidth = randomIntFromInterval(900, 960);		
				const randomIntegerHeight = randomIntFromInterval(630, 672);			
				const photo = {
					src: FULL_ALBUM_IMAGE_PATH + `${index + 1}` + ALBUM_IMAGE_EXTENSION,
					width: randomIntegerWidth,
					height: randomIntegerHeight
				}
				listPhoto.push(photo)

			}
		}

		console.log(listPhoto);
		return listPhoto;
	}

  return(
		<section className="full-album " >
			<div className='wide'>
				<div className="full-album--header">
					
					<p>Full Album</p>
					<p className="header-description">
						Hãy ấn vào từng bức ảnh để có thể xem rõ hơn!
					</p>
					<Link className='header-link button button--primary' to={`/`}>Quay trở về</Link>
				</div>
				<div className="full-album--content">
					<PhotoAlbum photos={processWholeAlbumPhoto()} layout={"rows"} columns={3} onClick={({ index }) => setIndex(index)}/>
					<Lightbox
                slides={processWholeAlbumPhoto()}
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
                // enable optional lightbox plugins
                plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
            />
				</div>

			</div>
		</section>

  )
};

export default FullAlbum;