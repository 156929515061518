
import {Link} from 'react-router-dom';
import {ALBUM_IMAGE_PATH, ALBUM_IMAGE_EXTENSION, TOTAL_IMAGE_IN_ALBUM, ALBUM_MOBILE_IMAGE_PATH} from'../../utils/constant/_constant'
import { randomIntFromInterval } from '../../utils/utils-function'
import PhotoAlbum from "react-photo-album";

const Album = () => {
	const processWholeAlbumPhoto = () => {
		const listPhoto = [];
		for (let index = 0; index < TOTAL_IMAGE_IN_ALBUM; index++) {
			const randomIntegerWidth = randomIntFromInterval(634, 670);		
			const randomIntegerHeight = randomIntFromInterval(970, 1025);	
			const photo = {
				src: ALBUM_IMAGE_PATH + `${index + 1}` + ALBUM_IMAGE_EXTENSION,
				width: randomIntegerWidth,
				height: randomIntegerHeight
			}
			listPhoto.push(photo)
		}

		console.log(listPhoto);
		return listPhoto;
	}

  return(
		<section className="album " >
			<div className='wide'>
				<div className="album--header">
					<p>Album cưới</p>
					<p className="header-description">
						Những khoảnh khắc hạnh phúc khó có thể phai nhòa
					</p>
					<Link className='header-link button button--primary' to={`/full-album`}>Xem Full Album</Link>
				</div>
				<div className="album--content">
					<PhotoAlbum photos={processWholeAlbumPhoto()} layout={"masonry"} columns={3}/>
				</div>

			</div>
		</section>

  )
};

export default Album;