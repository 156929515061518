import {get} from '../../service/http-request';
import { useEffect, useState } from 'react';
import {BUTTON_LOADING_CLASS} from '../../utils/constant/_constant';
const Comment = () => {
	const [listComment, setListComment] = useState([]);
	const [loadingClass, setloadingClass] = useState("");

	const onSubmitForm = () => {
		const nameInput = document.querySelector('#name');
		const emailInput = document.querySelector('#email');
		const contentInput = document.querySelector('#content');

		const nameValue = nameInput.value ? nameInput.value : "Ẩn Danh";
		const emailValue = emailInput.value ? emailInput.value : "Ẩn Danh";
		const contentValue = contentInput.value ? contentInput.value : "💕💕💕💕💕💕💕";
		debugger
		const url = `https://addcomment-${process.env.REACT_APP_firebase_key}-uc.a.run.app?name=${nameValue}&email=${emailValue}&content=${contentValue}`;
		setloadingClass(BUTTON_LOADING_CLASS);
		get(url).then((response) => {
			
			nameInput.value = '';
			emailInput.value = '';
			contentInput.value = ''
			loadAllComment();
		}).catch(error => {
			console.error(error)
			nameInput.value = '';
			emailInput.value = '';
			contentInput.value = ''
		}).finally(() => {
			setloadingClass("not-loaded");
		})

		
	}

	const loadAllComment = () => {
		const url = `https://loadcomment-${process.env.REACT_APP_firebase_key}-uc.a.run.app`
		get(url).then(response => {
			
			const listComment = response.data.data;
			if(listComment && listComment.length > 0){
				
				setListComment(listComment);
			}	
		}).catch(error => {
			console.error(error)
		})
	}


	useEffect(() => {
		loadAllComment();
	},[]);

  return(
		<section className="comment" >
			<div className='wide'>
				<div className='comment--header'>
					<p>Sổ lưu bút</p>
					<p className='header-description'>
						Tình yêu không phải là việc chúng ta nhìn về phía nhau mà là khi chúng ta cùng nhìn về một hướng
					</p>
				</div>
				<div className='comment--content'>
					<div className='content content-form'>
						<p>Cảm ơn bạn rất nhiều vì những lời chúc tuyệt vời dành cho đám cưới của chúng tôi</p>
						<input className='text' id="name" type='text' placeholder='Họ và tên'/>
						<input className='email' id="email" type='email' placeholder='Nhập email'/>
						<textarea className='text-area' id="content" rows="4" placeholder='Nhập nội dung'></textarea>
						<button className={'content-form-button button button--primary ' + loadingClass} onClick={() => onSubmitForm()}>
							<span className='button-text'>Gửi lời chúc</span>
							<span className='button-loading-icon icons icons-loading'></span>
						</button>
					</div>
					<div className='content content-data'>
						
						{	listComment.map((singleComment, index) => (
								
							<div key={index} className="content-data--item">
								<div className="item-name">{singleComment.name}</div>
								<div className="item-content">{singleComment.content}</div>
							</div>))
						}

					</div>
				</div>

			</div>
		</section>

  )
};

export default Comment;