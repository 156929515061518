import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import FullAlbum from './_full-album/_index';
import ErrorPage from './_404/_index';
import InvitationInput from './invitation/_index';
import InvitationData from './invitation/result-invitation/_index';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />
  },
  {
    path: "/full-album",
    element: <FullAlbum />,
  },
  {
    path: "/invitation",
    element: <InvitationInput />,
    children: [
      
    ]
  },
  {
    path: "/invitation/:name",
    element: <InvitationData isGroom={true}/>
  },
  {
    path: "/invitation-bride/:name",
    element: <InvitationData isGroom={false}/>
  }, 
  {
    path: "/invitation-default/:name",
    element: <InvitationData isGroom={true} isDefault={true}/>
  }

]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
