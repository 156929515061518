
import {Link} from 'react-router-dom';
import {useState} from 'react';

const InvitationInput = () => {
	const [inputName, setInputName] = useState("");
	const [isGroom, setIsGroom] = useState(false);
	const onSubmitForm = () => {

	}

	const handleChangeName = (event) => {
		setInputName(event.target.value);
	}

	const handleChangeRadio = (event) => {
		const targetId = event.target.getAttribute('id');
		if(targetId == 'radio-groom'){
			setIsGroom(true);
		}else{
			setIsGroom(false);
		}
	}
  return(
    <>
		<section className='invitation-input'>
			<div className='content-form'>
				<p>Xin hãy điền tên vào đây để lấy thiệp</p>
				<div className='form-group'>
					<input className='text' id="name-invitation-input" type='text' placeholder='Họ và tên' onChange={handleChangeName}/>
				</div>
				<div className='form-group'>
					<input className='radio' id="radio-bride" name='radio-bride' type="radio" onChange={handleChangeRadio}/>
					<label htmlFor="radio-bride">Tiệc cưới nhà gái</label>
				</div>
				<div className='form-group'>
					<input className='radio' id="radio-groom" name='radio-groom' type="radio" onChange={handleChangeRadio}/>
					<label htmlFor="radio-groom">Tiệc cưới nhà trai</label>
				</div>
				<Link to={`/invitation${!isGroom ? '-bride' : ''}/` + inputName} className={'content-form-button button button--primary'} onClick={() => onSubmitForm()}>
					<span className='button-text'>Lấy thiệp</span>
					<span className='button-loading-icon icons icons-loading'></span>
				</Link>
			</div>
		</section>

      
    </>
  )
};

export default InvitationInput;