// import '../style/miscalenous/_icons.scss'
const TopBanner = () => {
  return(
    <>
      <section className="top-banner" >

        <div className="top-banner--text-wrapper">
          <div className="text-wrapper--title">
            <h1>Our's Wedding</h1>
          </div>
          <div className="text-wrapper--description">Duy Quang&nbsp;<div className="icons icons-red-heart"></div>&nbsp;Huong Giang</div>
        </div>
          
      </section>

      
    </>
  )
};

export default TopBanner;