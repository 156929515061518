
import groomImage from '../../../resouces/thiep/nhà trai.jpg'
import brideImage from '../../../resouces/thiep/nhà gái.jpg'
import vuQuyImage from '../../../resouces/thiep/vu-quy.jpg'
const Invitation = ({isGroom, isShow, clientName, isVuQuy}) => {
	const onHideInvitation = () => {
		const overlay = document.querySelector('.overlay');
		const onlineInvitationElement = document.querySelector('.online-invitation');
		onlineInvitationElement.style.display = 'none'
		overlay.style.display = 'none';
		document.body.style.overflowY = "auto";
	}
	const imageSource = (() => {
		let imageHref = "";

		if(isVuQuy){
			imageHref = vuQuyImage;
		}else {

			imageHref = isGroom ? groomImage : brideImage;
		}
		return imageHref
	})();

  return(
    <>
      <div className={`online-invitation`} >
				<div className='close-invitation' onClick={() => onHideInvitation()}>
					<span className='icons icons-close'></span>
				</div>
				<div className="image-wrapper">
					<div className='client-name'>{clientName}</div>
					<picture>
						<source srcSet={imageSource} media="(min-width: 600px)" />
						<img src={imageSource} alt="MDN" />
					</picture>
					{/* <img src={groomImage} alt="groom"></img> */}
				</div>
				<div className="invitation-function">
					<a href={imageSource} className='button button--primary download-image' download>
						Tải thiệp
					</a>
				</div>
        
          
      </div>

      
    </>
  )
};

export default Invitation;