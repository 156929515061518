import {Link} from 'react-router-dom';
function Navigation() {
  return (
    <>
      <section className="navigation">
				<div className='navigation--wrapper wide'>
					<div className="nav-header">Q & G</div>
					<div className="nav-wrapper">
						<div className="nav-item">
							
							<Link to={`/full-album`}>Full Album</Link>
						</div>
					</div>

				</div>
			</section>
    </>
  );
}

export default Navigation;
