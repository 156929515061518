
// import '../../../style/online-invitation.scss'
// import '../style/miscalenous/_icons.scss'

import {useParams} from "react-router-dom";

import groomImage from '../../resouces/thiep/nhà trai.jpg'
import brideImage from '../../resouces/thiep/nhà gái.jpg'
import defaultImage from '../../resouces/thiep/nhà-trai-kh-anh.jpg'
import {useEffect, useState} from "react";
import domtoimage from 'dom-to-image';

const InvitationData = ({isGroom, isDefault}) => {
	const { name } = useParams();
	const imageSource = (() => {
		let imageHref = "";

		if(isDefault){
			imageHref = defaultImage;
		}else {

			imageHref = isGroom ? groomImage : brideImage;
		}
		return imageHref
	})();

	const calculateTextPosition = () => {
		const clientName = document.querySelector('.client-name');
		const imageWrapper = document.querySelector('.image-wrapper');
		if(clientName && imageWrapper){
			const clientNameWidth = clientName.getBoundingClientRect().width;
			const imageWrapperWidth = imageWrapper.getBoundingClientRect().width;
			const totalRedundanceWidth = (imageWrapperWidth * 0.45) - clientNameWidth;
			clientName.style.left = `${16 + totalRedundanceWidth / 2}px`
		}

	}

	const downloadImage = () => {
		// const node = document.querySelector('.image-wrapper');
		// domtoimage.toPng(node)
    // .then(function (dataUrl) {
    //     var img = new Image();
    //     img.src = dataUrl;
    //     // document.body.appendChild(img);
    // })
    // .catch(function (error) {
    //     console.error('oops, something went wrong!', error);
    // });

		domtoimage.toJpeg(document.querySelector('.image-wrapper'), { quality: 0.95 })
    .then(function (dataUrl) {
        var link = document.createElement('a');
        link.download = 'my-image-name.jpeg';
        link.href = dataUrl;
        link.click();
    });
	}

	useEffect(() => {
		calculateTextPosition();
	}, [])
  return(
    <>
      <section className={`result-invitation wide ${isDefault ? 'default' : ''}`} >
				<div className="image-wrapper">
					<div className='client-name'>{name}</div>
					<picture>
						<source srcSet={imageSource} media="(min-width: 600px)" />
						<img src={imageSource} alt="MDN" />
					</picture>
					{/* <img src={groomImage} alt="groom"></img> */}
				</div>
				<div className="invitation-function">
					<button onClick={() => downloadImage()} className='button button--primary download-image' download>
						Tải thiệp
					</button>
				</div>
          
      </section>

      
    </>
  )
};

export default InvitationData;