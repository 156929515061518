export const ADDRESS_WIFE = {
	longtitude: 105.69924516006068,
	latitude: 20.865853449172093
}

export const ADDRESS_HUSBAND = {
	longtitude: 106.705788,
	latitude: 20.828855
}
 
export const ADDRES_WEDDING_SITE_HUSBAND = {
	longtitude: 106.69243574480545,
	latitude: 20.862791885882395
}

export const ADDRES_WEDDING_SITE_WIFE = {
	longtitude: 105.69899817891682,
	latitude: 20.864350506056873
}

export const ALBUM_IMAGE_PATH = '/album-mobile/';
export const FULL_ALBUM_IMAGE_PATH = '/album-book-mobile/'
export const ALBUM_IMAGE_EXTENSION = '.jpg';
export const TOTAL_IMAGE_IN_ALBUM = 12;
export const TOTAL_IMAGE_FULL_ALBUM = 17;
export const BASE_BREAKPOINT = {
	MAX_MOBILE: 768
}

export const BUTTON_LOADING_CLASS = 'loaded';