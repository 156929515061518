import './App.scss';
import TopBanner from './homepage/_top-banner/_index';
import Introduction from './homepage/_introduction/_index';
import Countdown from './homepage/_countdown/_index';
import Timeline from './homepage/_timeline/_index';
import Album from './homepage/_album/_index';
import {Outlet} from 'react-router-dom';
import Navigation from './navigation/_index';
import Comment from './homepage/_comment/_index';
function App() {
  return (
    <>
      {/* <Navigation /> */}
      <TopBanner />
      <Introduction />
      <Countdown />
      <Timeline/>
      <Album />
      <Comment />
    </>
  );
}

export default App;
