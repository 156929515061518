import groomImage from '../../resouces/groom.jpg'
import brideImage from '../../resouces/bride.jpg'
import {Rotate} from 'react-awesome-reveal';
const Introduction = () => {
  return(
		<section className="introduction wide" >
				<div className="introduction--title">
					<div className="main-title">Chàng và Nàng</div>
					<div className="sub-title">Cả thế giới của anh chỉ dành cho một mình em thôi.</div>
				</div>
        <div className="introduction--wrapper">
					<div className="introduction--column">
						
						<div className="column-image">
							<Rotate fraction={0.8} duration={1500} triggerOnce={true}>
								<div className="image-wrapper">
									<img src={groomImage} alt="groom"></img>
								</div>

							</Rotate>
						</div>
						<div className="column-title">Duy Quang</div>
						<div className="column-description">
Duy Quang, một lập trình viên nhưng đam mê làm ca sĩ bởi tên của anh gắn liền với ca sĩ nổi tiếng thập niên 80 - Duy Quang. Người ta thường ví cuộc đời mỗi người như một bộ phim, bằng giọng ca “trời phú”, cộng thêm gương mặt và tố chất diễn viên “hài”, Duy Quang đã hoàn thành xuất sắc vai “người yêu của Hương Giang” trong bộ phim “Cuộc đời của Duy Quang”. Giờ đây anh chuẩn bị đảm nhiệm một vai mới, một vai quan trọng nhất trong cuộc đời anh, đó là trở thành bạn đời của Hương Giang
						</div>
					</div>
					<div className="introduction--column">
						<div className="column-image">
							<Rotate fraction={0.8} duration={1500} triggerOnce={true}>
								<div className="image-wrapper">
									<img src={brideImage} alt="bride"></img>
								</div>

							</Rotate>							
						
						</div>
						<div className="column-title">Hương Giang</div>
						<div className="column-description">
						Hương Giang, truyền nhân cùng tên, khác cha, khác mẹ, kém 10 tuổi của Miss Asia Vietnam 2007 - Trần Thị Hương Giang. Sở hữu nụ cười duyên dáng, chiều cao lý tưởng, cùng với thành tích đáng phải kể đến như là “Người yêu hay dỗi” hay “Nhà biên kịch tài ba” do Duy Quang công nhận, Hương Giang đã vinh dự chiến thắng và đoạt ngôi vị cao nhất và duy nhất tại cuộc thi tầm cỡ quốc tế “Hoa hậu trong lòng Duy Quang” và “Người đẹp bên Duy Quang”, sau khi vượt qua hàng tỷ thí sinh không bao giờ được xét duyệt hồ sơ
						</div>
					</div>
        </div>
          
      </section>

  )
};

export default Introduction;